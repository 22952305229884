import React, { useCallback, useEffect, useRef, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import Icon from '../../../../base/icons/components/Icon';
import { IconCheck, IconExclamationSolid } from '../../../../base/icons/svg';
import JitsiMeetJS from '../../../../base/lib-jitsi-meet/_';
import ContextMenuItem from '../../../../base/ui/components/web/ContextMenuItem';
import { TEXT_OVERFLOW_TYPES } from '../../../../base/ui/constants.any';
import Meter from './Meter';
const JitsiTrackEvents = JitsiMeetJS.events.track;
const useStyles = makeStyles()(theme => {
    return {
        container: {
            position: 'relative'
        },
        entryText: {
            maxWidth: '238px',
            '&.withMeter': {
                maxWidth: '178px'
            },
            '&.left-margin': {
                marginLeft: '36px'
            }
        },
        icon: {
            borderRadius: '50%',
            display: 'inline-block',
            width: '14px',
            marginLeft: '6px',
            '& svg': {
                fill: theme.palette.iconError
            }
        },
        meter: {
            position: 'absolute',
            right: '16px',
            top: '14px'
        }
    };
});
const MicrophoneEntry = ({ deviceId, children, hasError, index, isSelected, length, jitsiTrack, listHeaderId, measureAudioLevels, onClick: propsClick }) => {
    const [level, setLevel] = useState(-1);
    const activeTrackRef = useRef(jitsiTrack);
    const { classes, cx } = useStyles();
    /**
     * Click handler for the entry.
     *
     * @returns {void}
     */
    const onClick = useCallback(() => {
        propsClick(deviceId);
    }, [propsClick, deviceId]);
    /**
     * Key pressed handler for the entry.
     *
     * @param {Object} e - The event.
     * @private
     *
     * @returns {void}
     */
    const onKeyPress = useCallback((e) => {
        if (e.key === ' ') {
            e.preventDefault();
            propsClick(deviceId);
        }
    }, [propsClick, deviceId]);
    /**
     * Updates the level of the meter.
     *
     * @param {number} num - The audio level provided by the jitsiTrack.
     * @returns {void}
     */
    const updateLevel = useCallback((num) => {
        setLevel(Math.floor(num / 0.125));
    }, []);
    /**
     * Subscribes to audio level changes coming from the jitsiTrack.
     *
     * @returns {void}
     */
    const startListening = () => {
        jitsiTrack && measureAudioLevels && jitsiTrack.on(JitsiTrackEvents.TRACK_AUDIO_LEVEL_CHANGED, updateLevel);
    };
    /**
     * Unsubscribes from changes coming from the jitsiTrack.
     *
     * @param {Object} track - The jitsiTrack to unsubscribe from.
     * @returns {void}
     */
    const stopListening = (track) => {
        track?.off(JitsiTrackEvents.TRACK_AUDIO_LEVEL_CHANGED, updateLevel);
        setLevel(-1);
    };
    useEffect(() => {
        startListening();
        return () => {
            stopListening(jitsiTrack);
        };
    }, []);
    useEffect(() => {
        stopListening(activeTrackRef.current);
        startListening();
        activeTrackRef.current = jitsiTrack;
    }, [jitsiTrack]);
    const deviceTextId = `choose_microphone${deviceId}`;
    const labelledby = `${listHeaderId} ${deviceTextId} `;
    return (React.createElement("li", { "aria-checked": isSelected, "aria-labelledby": labelledby, "aria-posinset": index, "aria-setsize": length, className: classes.container, onClick: onClick, onKeyPress: onKeyPress, role: 'radio', tabIndex: 0 },
        React.createElement(ContextMenuItem, { accessibilityLabel: '', icon: isSelected ? IconCheck : undefined, overflowType: TEXT_OVERFLOW_TYPES.SCROLL_ON_HOVER, selected: isSelected, text: children, textClassName: cx(classes.entryText, measureAudioLevels && 'withMeter', !isSelected && 'left-margin') }, hasError && React.createElement(Icon, { className: classes.icon, size: 16, src: IconExclamationSolid })),
        Boolean(jitsiTrack) && measureAudioLevels && React.createElement(Meter, { className: classes.meter, isDisabled: hasError, level: level })));
};
export default MicrophoneEntry;
