import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { isMobileBrowser } from '../../../base/environment/utils';
import { translate } from '../../../base/i18n/functions';
import { IconArrowUp, IconFaceSmile } from '../../../base/icons/svg';
import AbstractButton from '../../../base/toolbox/components/AbstractButton';
import ToolboxButtonWithPopup from '../../../base/toolbox/components/web/ToolboxButtonWithPopup';
import { toggleReactionsMenuVisibility } from '../../actions.web';
import { getReactionsQueue, isReactionsEnabled } from '../../functions.any';
import { getReactionsMenuVisibility, isReactionsButtonEnabled } from '../../functions.web';
import { IReactionsMenuParent } from '../../types';
import RaiseHandButton from './RaiseHandButton';
import ReactionEmoji from './ReactionEmoji';
import ReactionsMenu from './ReactionsMenu';
/**
 * Implementation of a button for reactions.
 */
class ReactionsButtonImpl extends AbstractButton {
    constructor() {
        super(...arguments);
        this.accessibilityLabel = 'toolbar.accessibilityLabel.reactions';
        this.icon = IconFaceSmile;
        this.label = 'toolbar.reactions';
        this.toggledLabel = 'toolbar.reactions';
        this.tooltip = 'toolbar.reactions';
    }
}
const ReactionsButton = translate(connect()(ReactionsButtonImpl));
/**
 * Button used for the reactions menu.
 *
 * @returns {ReactElement}
 */
function ReactionsMenuButton({ _reactionsButtonEnabled, _isMobile, buttonKey, dispatch, handleClick, isOpen, isNarrow, notifyMode, reactionsQueue, t }) {
    const toggleReactionsMenu = useCallback(() => {
        dispatch(toggleReactionsMenuVisibility());
    }, [dispatch]);
    const openReactionsMenu = useCallback(() => {
        !isOpen && toggleReactionsMenu();
    }, [isOpen, toggleReactionsMenu]);
    const closeReactionsMenu = useCallback(() => {
        isOpen && toggleReactionsMenu();
    }, [isOpen, toggleReactionsMenu]);
    const reactionsMenu = (React.createElement("div", { className: 'reactions-menu-container' },
        React.createElement(ReactionsMenu, { parent: IReactionsMenuParent.Button })));
    let content = null;
    if (_reactionsButtonEnabled) {
        content = (React.createElement(ToolboxButtonWithPopup, { ariaControls: 'reactions-menu-dialog', ariaExpanded: isOpen, ariaHasPopup: true, ariaLabel: t('toolbar.accessibilityLabel.reactionsMenu'), onPopoverClose: closeReactionsMenu, onPopoverOpen: openReactionsMenu, popoverContent: reactionsMenu, trigger: _isMobile ? 'click' : undefined, visible: isOpen },
            React.createElement(ReactionsButton, { buttonKey: buttonKey, notifyMode: notifyMode })));
    }
    else {
        content = isNarrow
            ? (React.createElement(RaiseHandButton, { buttonKey: buttonKey, handleClick: handleClick, notifyMode: notifyMode }))
            : (React.createElement(ToolboxButtonWithPopup, { ariaControls: 'reactions-menu-dialog', ariaExpanded: isOpen, ariaHasPopup: true, ariaLabel: t('toolbar.accessibilityLabel.reactionsMenu'), icon: IconArrowUp, iconDisabled: false, iconId: 'reactions-menu-button', onPopoverClose: toggleReactionsMenu, onPopoverOpen: openReactionsMenu, popoverContent: reactionsMenu, visible: isOpen },
                React.createElement(RaiseHandButton, { buttonKey: buttonKey, handleClick: handleClick, notifyMode: notifyMode })));
    }
    return (React.createElement("div", { className: 'reactions-menu-popup-container' },
        content,
        reactionsQueue.map(({ reaction, uid }, index) => (React.createElement(ReactionEmoji, { index: index, key: uid, reaction: reaction, uid: uid })))));
}
/**
 * Function that maps parts of Redux state tree into component props.
 *
 * @param {Object} state - Redux state.
 * @returns {Object}
 */
function mapStateToProps(state) {
    const { isNarrowLayout } = state['features/base/responsive-ui'];
    return {
        _reactionsButtonEnabled: isReactionsButtonEnabled(state),
        _reactionsEnabled: isReactionsEnabled(state),
        _isMobile: isMobileBrowser(),
        isOpen: getReactionsMenuVisibility(state),
        isNarrow: isNarrowLayout,
        reactionsQueue: getReactionsQueue(state)
    };
}
export default translate(connect(mapStateToProps)(ReactionsMenuButton));
