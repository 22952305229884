/* eslint-disable react/jsx-no-bind */
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import Avatar from '../../../base/avatar/components/Avatar';
import { isNameReadOnly } from '../../../base/config/functions.web';
import { IconArrowDown, IconArrowUp, IconPhoneRinging, IconVolumeOff } from '../../../base/icons/svg';
import { isVideoMutedByUser } from '../../../base/media/functions';
import { getLocalParticipant } from '../../../base/participants/functions';
import Popover from '../../../base/popover/components/Popover.web';
import ActionButton from '../../../base/premeeting/components/web/ActionButton';
import PreMeetingScreen from '../../../base/premeeting/components/web/PreMeetingScreen';
import { updateSettings } from '../../../base/settings/actions';
import { getDisplayName } from '../../../base/settings/functions.web';
import { withPixelLineHeight } from '../../../base/styles/functions.web';
import { getLocalJitsiVideoTrack } from '../../../base/tracks/functions.web';
import Button from '../../../base/ui/components/web/Button';
import Input from '../../../base/ui/components/web/Input';
import { BUTTON_TYPES } from '../../../base/ui/constants.any';
import isInsecureRoomName from '../../../base/util/isInsecureRoomName';
import { joinConference as joinConferenceAction, joinConferenceWithoutAudio as joinConferenceWithoutAudioAction, setJoinByPhoneDialogVisiblity as setJoinByPhoneDialogVisiblityAction } from '../../actions.web';
import { isDeviceStatusVisible, isDisplayNameRequired, isJoinByPhoneButtonVisible, isJoinByPhoneDialogVisible, isPrejoinDisplayNameVisible } from '../../functions';
import JoinByPhoneDialog from './dialogs/JoinByPhoneDialog';
const useStyles = makeStyles()(theme => {
    return {
        inputContainer: {
            width: '100%'
        },
        input: {
            width: '100%',
            marginBottom: theme.spacing(3),
            '& input': {
                textAlign: 'center'
            }
        },
        avatarContainer: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column'
        },
        avatar: {
            margin: `${theme.spacing(2)} auto ${theme.spacing(3)}`
        },
        avatarName: {
            ...withPixelLineHeight(theme.typography.bodyShortBoldLarge),
            color: theme.palette.text01,
            marginBottom: theme.spacing(5),
            textAlign: 'center'
        },
        error: {
            backgroundColor: theme.palette.actionDanger,
            color: theme.palette.text01,
            borderRadius: theme.shape.borderRadius,
            width: '100%',
            ...withPixelLineHeight(theme.typography.labelRegular),
            boxSizing: 'border-box',
            padding: theme.spacing(1),
            textAlign: 'center',
            marginTop: `-${theme.spacing(2)}`,
            marginBottom: theme.spacing(3)
        },
        dropdownContainer: {
            position: 'relative',
            width: '100%'
        },
        dropdownButtons: {
            width: '300px',
            padding: '8px 0',
            backgroundColor: theme.palette.action02,
            color: theme.palette.text04,
            borderRadius: theme.shape.borderRadius,
            position: 'relative',
            top: `-${theme.spacing(3)}`
        }
    };
});
const Prejoin = ({ canEditDisplayName, deviceStatusVisible, hasJoinByPhoneButton, joinConference, joinConferenceWithoutAudio, joiningInProgress, name, participantId, prejoinConfig, readOnlyName, setJoinByPhoneDialogVisiblity, showCameraPreview, showDialog, showErrorOnJoin, showUnsafeRoomWarning, unsafeRoomConsent, updateSettings: dispatchUpdateSettings, videoTrack }) => {
    const showDisplayNameField = useRef(canEditDisplayName || showErrorOnJoin);
    const [showJoinByPhoneButtons, setShowJoinByPhoneButtons] = useState(false);
    const { classes } = useStyles();
    const { t } = useTranslation();
    /**
     * Handler for the join button.
     *
     * @param {Object} e - The synthetic event.
     * @returns {void}
     */
    const onJoinButtonClick = () => {
        if (showErrorOnJoin) {
            return;
        }
        joinConference();
    };
    /**
     * Closes the dropdown.
     *
     * @returns {void}
     */
    const onDropdownClose = () => {
        setShowJoinByPhoneButtons(false);
    };
    /**
     * Displays the join by phone buttons dropdown.
     *
     * @param {Object} e - The synthetic event.
     * @returns {void}
     */
    const onOptionsClick = (e) => {
        e?.stopPropagation();
        setShowJoinByPhoneButtons(show => !show);
    };
    /**
     * Sets the guest participant name.
     *
     * @param {string} displayName - Participant name.
     * @returns {void}
     */
    const setName = (displayName) => {
        dispatchUpdateSettings({
            displayName
        });
    };
    /**
     * Closes the join by phone dialog.
     *
     * @returns {undefined}
     */
    const closeDialog = () => {
        setJoinByPhoneDialogVisiblity(false);
    };
    /**
     * Displays the dialog for joining a meeting by phone.
     *
     * @returns {undefined}
     */
    const doShowDialog = () => {
        setJoinByPhoneDialogVisiblity(true);
        onDropdownClose();
    };
    /**
     * KeyPress handler for accessibility.
     *
     * @param {Object} e - The key event to handle.
     *
     * @returns {void}
     */
    const showDialogKeyPress = (e) => {
        if (e.key === ' ' || e.key === 'Enter') {
            e.preventDefault();
            doShowDialog();
        }
    };
    /**
     * KeyPress handler for accessibility.
     *
     * @param {Object} e - The key event to handle.
     *
     * @returns {void}
     */
    const onJoinConferenceWithoutAudioKeyPress = (e) => {
        if (joinConferenceWithoutAudio
            && (e.key === ' '
                || e.key === 'Enter')) {
            e.preventDefault();
            joinConferenceWithoutAudio();
        }
    };
    /**
     * Gets the list of extra join buttons.
     *
     * @returns {Object} - The list of extra buttons.
     */
    const getExtraJoinButtons = () => {
        const noAudio = {
            key: 'no-audio',
            testId: 'prejoin.joinWithoutAudio',
            icon: IconVolumeOff,
            label: t('prejoin.joinWithoutAudio'),
            onClick: joinConferenceWithoutAudio,
            onKeyPress: onJoinConferenceWithoutAudioKeyPress
        };
        const byPhone = {
            key: 'by-phone',
            testId: 'prejoin.joinByPhone',
            icon: IconPhoneRinging,
            label: t('prejoin.joinAudioByPhone'),
            onClick: doShowDialog,
            onKeyPress: showDialogKeyPress
        };
        return {
            noAudio,
            byPhone
        };
    };
    /**
     * Handle keypress on input.
     *
     * @param {KeyboardEvent} e - Keyboard event.
     * @returns {void}
     */
    const onInputKeyPress = (e) => {
        if (e.key === 'Enter') {
            joinConference();
        }
    };
    const extraJoinButtons = getExtraJoinButtons();
    let extraButtonsToRender = Object.values(extraJoinButtons).filter((val) => !(prejoinConfig?.hideExtraJoinButtons || []).includes(val.key));
    if (!hasJoinByPhoneButton) {
        extraButtonsToRender = extraButtonsToRender.filter((btn) => btn.key !== 'by-phone');
    }
    const hasExtraJoinButtons = Boolean(extraButtonsToRender.length);
    return (React.createElement(PreMeetingScreen, { showDeviceStatus: deviceStatusVisible, showUnsafeRoomWarning: showUnsafeRoomWarning, title: t('prejoin.joinMeeting'), videoMuted: !showCameraPreview, videoTrack: videoTrack },
        React.createElement("div", { className: classes.inputContainer, "data-testid": 'prejoin.screen' },
            showDisplayNameField.current ? (React.createElement(Input, { autoComplete: 'name', autoFocus: true, className: classes.input, error: showErrorOnJoin, onChange: setName, onKeyPress: showUnsafeRoomWarning && !unsafeRoomConsent ? undefined : onInputKeyPress, placeholder: t('dialog.enterDisplayName'), readOnly: readOnlyName, value: name })) : (React.createElement("div", { className: classes.avatarContainer },
                React.createElement(Avatar, { className: classes.avatar, displayName: name, participantId: participantId, size: 72 }),
                React.createElement("div", { className: classes.avatarName }, name))),
            showErrorOnJoin && React.createElement("div", { className: classes.error, "data-testid": 'prejoin.errorMessage' }, t('prejoin.errorMissingName')),
            React.createElement("div", { className: classes.dropdownContainer },
                React.createElement(Popover, { content: hasExtraJoinButtons && React.createElement("div", { className: classes.dropdownButtons }, extraButtonsToRender.map(({ key, ...rest }) => (React.createElement(Button, { disabled: joiningInProgress, fullWidth: true, key: key, type: BUTTON_TYPES.SECONDARY, ...rest })))), onPopoverClose: onDropdownClose, position: 'bottom', trigger: 'click', visible: showJoinByPhoneButtons },
                    React.createElement(ActionButton, { OptionsIcon: showJoinByPhoneButtons ? IconArrowUp : IconArrowDown, ariaDropDownLabel: t('prejoin.joinWithoutAudio'), ariaLabel: t('prejoin.joinMeeting'), ariaPressed: showJoinByPhoneButtons, disabled: joiningInProgress || (showUnsafeRoomWarning && !unsafeRoomConsent), hasOptions: hasExtraJoinButtons, onClick: onJoinButtonClick, onOptionsClick: onOptionsClick, role: 'button', tabIndex: 0, testId: 'prejoin.joinMeeting', type: 'primary' }, t('prejoin.joinMeeting'))))),
        showDialog && (React.createElement(JoinByPhoneDialog, { joinConferenceWithoutAudio: joinConferenceWithoutAudio, onClose: closeDialog }))));
};
/**
 * Maps (parts of) the redux state to the React {@code Component} props.
 *
 * @param {Object} state - The redux state.
 * @returns {Object}
 */
function mapStateToProps(state) {
    const name = getDisplayName(state);
    const showErrorOnJoin = isDisplayNameRequired(state) && !name;
    const { id: participantId } = getLocalParticipant(state) ?? {};
    const { joiningInProgress } = state['features/prejoin'];
    const { room } = state['features/base/conference'];
    const { enableInsecureRoomNameWarning = false } = state['features/base/config'];
    const { unsafeRoomConsent } = state['features/base/premeeting'];
    return {
        canEditDisplayName: isPrejoinDisplayNameVisible(state),
        deviceStatusVisible: isDeviceStatusVisible(state),
        hasJoinByPhoneButton: isJoinByPhoneButtonVisible(state),
        joiningInProgress,
        name,
        participantId,
        prejoinConfig: state['features/base/config'].prejoinConfig,
        readOnlyName: isNameReadOnly(state),
        showCameraPreview: !isVideoMutedByUser(state),
        showDialog: isJoinByPhoneDialogVisible(state),
        showErrorOnJoin,
        showUnsafeRoomWarning: isInsecureRoomName(room) && enableInsecureRoomNameWarning,
        unsafeRoomConsent,
        videoTrack: getLocalJitsiVideoTrack(state)
    };
}
const mapDispatchToProps = {
    joinConferenceWithoutAudio: joinConferenceWithoutAudioAction,
    joinConference: joinConferenceAction,
    setJoinByPhoneDialogVisiblity: setJoinByPhoneDialogVisiblityAction,
    updateSettings
};
export default connect(mapStateToProps, mapDispatchToProps)(Prejoin);
