import React from 'react';
import { connect } from 'react-redux';
import { translate } from '../../base/i18n/functions';
import { IconE2EE } from '../../base/icons/svg';
import Label from '../../base/label/components/web/Label';
import { COLORS } from '../../base/label/constants';
import Tooltip from '../../base/tooltip/components/Tooltip';
import { _mapStateToProps } from './AbstractE2EELabel';
const E2EELabel = ({ _e2eeLabels, _showLabel, t }) => {
    if (!_showLabel) {
        return null;
    }
    const content = _e2eeLabels?.labelToolTip || t('e2ee.labelToolTip');
    return (React.createElement(Tooltip, { content: content, position: 'bottom' },
        React.createElement(Label, { color: COLORS.green, icon: IconE2EE })));
};
export default translate(connect(_mapStateToProps)(E2EELabel));
