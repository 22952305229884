import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { hideDialog } from '../../../dialog/actions';
import { IconCloseLarge } from '../../../icons/svg';
import { withPixelLineHeight } from '../../../styles/functions.web';
import BaseDialog from './BaseDialog';
import Button from './Button';
import ClickableIcon from './ClickableIcon';
const useStyles = makeStyles()(theme => {
    return {
        header: {
            width: '100%',
            padding: '24px',
            boxSizing: 'border-box',
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'space-between'
        },
        title: {
            color: theme.palette.text01,
            ...withPixelLineHeight(theme.typography.heading5),
            margin: 0,
            padding: 0
        },
        content: {
            height: 'auto',
            overflowY: 'auto',
            width: '100%',
            boxSizing: 'border-box',
            padding: '0 24px',
            overflowX: 'hidden',
            minHeight: '40px',
            '@media (max-width: 448px)': {
                height: '100%'
            }
        },
        footer: {
            width: '100%',
            boxSizing: 'border-box',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: '24px',
            '& button:last-child': {
                marginLeft: '16px'
            }
        }
    };
});
const Dialog = ({ back = { hidden: true }, cancel = { translationKey: 'dialog.Cancel' }, children, className, description, disableAutoHideOnSubmit = false, disableBackdropClose, hideCloseButton, disableEnter, ok = { translationKey: 'dialog.Ok' }, onCancel, onSubmit, size, title, titleKey }) => {
    const { classes } = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const onClose = useCallback(() => {
        dispatch(hideDialog());
        onCancel?.();
    }, [onCancel]);
    const submit = useCallback(() => {
        !disableAutoHideOnSubmit && dispatch(hideDialog());
        onSubmit?.();
    }, [onSubmit]);
    return (React.createElement(BaseDialog, { className: className, description: description, disableBackdropClose: disableBackdropClose, disableEnter: disableEnter, onClose: onClose, size: size, submit: submit, title: title, titleKey: titleKey },
        React.createElement("div", { className: classes.header },
            React.createElement("p", { className: classes.title, id: 'dialog-title' }, title ?? t(titleKey ?? '')),
            !hideCloseButton && (React.createElement(ClickableIcon, { accessibilityLabel: t('dialog.accessibilityLabel.close'), icon: IconCloseLarge, id: 'modal-header-close-button', onClick: onClose }))),
        React.createElement("div", { className: classes.content, "data-autofocus-inside": 'true' }, children),
        React.createElement("div", { className: classes.footer, "data-autofocus-inside": 'true' },
            !back.hidden && React.createElement(Button, { accessibilityLabel: t(back.translationKey ?? ''), labelKey: back.translationKey, 
                // eslint-disable-next-line react/jsx-handler-names
                onClick: back.onClick, type: 'secondary' }),
            !cancel.hidden && React.createElement(Button, { accessibilityLabel: t(cancel.translationKey ?? ''), labelKey: cancel.translationKey, onClick: onClose, type: 'tertiary' }),
            !ok.hidden && React.createElement(Button, { accessibilityLabel: t(ok.translationKey ?? ''), disabled: ok.disabled, id: 'modal-dialog-ok-button', labelKey: ok.translationKey, onClick: submit }))));
};
export default Dialog;
