import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isMobileBrowser } from '../../../base/environment/utils';
import { translate } from '../../../base/i18n/functions';
import { IconArrowUp } from '../../../base/icons/svg';
import { IGUMPendingState } from '../../../base/media/types';
import ToolboxButtonWithIcon from '../../../base/toolbox/components/web/ToolboxButtonWithIcon';
import { getLocalJitsiVideoTrack } from '../../../base/tracks/functions.web';
import { toggleVideoSettings } from '../../../settings/actions';
import VideoSettingsPopup from '../../../settings/components/web/video/VideoSettingsPopup';
import { getVideoSettingsVisibility } from '../../../settings/functions.web';
import { isVideoSettingsButtonDisabled } from '../../functions.web';
import VideoMuteButton from '../VideoMuteButton';
/**
 * Button used for video & video settings.
 *
 * @returns {ReactElement}
 */
class VideoSettingsButton extends Component {
    /**
     * Initializes a new {@code VideoSettingsButton} instance.
     *
     * @inheritdoc
     */
    constructor(props) {
        super(props);
        this._onEscClick = this._onEscClick.bind(this);
        this._onClick = this._onClick.bind(this);
    }
    /**
     * Returns true if the settings icon is disabled.
     *
     * @returns {boolean}
     */
    _isIconDisabled() {
        const { gumPending, hasPermissions, hasVideoTrack, isDisabled } = this.props;
        return ((!hasPermissions || isDisabled) && !hasVideoTrack) || gumPending !== IGUMPendingState.NONE;
    }
    /**
     * Click handler for the more actions entries.
     *
     * @param {KeyboardEvent} event - Esc key click to close the popup.
     * @returns {void}
     */
    _onEscClick(event) {
        if (event.key === 'Escape' && this.props.isOpen) {
            event.preventDefault();
            event.stopPropagation();
            this._onClick();
        }
    }
    /**
     * Click handler for the more actions entries.
     *
     * @param {MouseEvent} e - Mousw event.
     * @returns {void}
     */
    _onClick(e) {
        const { onVideoOptionsClick, isOpen } = this.props;
        if (isOpen) {
            e?.stopPropagation();
        }
        onVideoOptionsClick();
    }
    /**
     * Implements React's {@link Component#render}.
     *
     * @inheritdoc
     */
    render() {
        const { gumPending, t, visible, isOpen, buttonKey, notifyMode } = this.props;
        return visible ? (React.createElement(VideoSettingsPopup, null,
            React.createElement(ToolboxButtonWithIcon, { ariaControls: 'video-settings-dialog', ariaExpanded: isOpen, ariaHasPopup: true, ariaLabel: this.props.t('toolbar.videoSettings'), buttonKey: buttonKey, icon: IconArrowUp, iconDisabled: this._isIconDisabled() || gumPending !== IGUMPendingState.NONE, iconId: 'video-settings-button', iconTooltip: t('toolbar.videoSettings'), notifyMode: notifyMode, onIconClick: this._onClick, onIconKeyDown: this._onEscClick },
                React.createElement(VideoMuteButton, { buttonKey: buttonKey, notifyMode: notifyMode })))) : React.createElement(VideoMuteButton, { buttonKey: buttonKey, notifyMode: notifyMode });
    }
}
/**
 * Function that maps parts of Redux state tree into component props.
 *
 * @param {Object} state - Redux state.
 * @returns {Object}
 */
function mapStateToProps(state) {
    const { permissions = { video: false } } = state['features/base/devices'];
    const { isNarrowLayout } = state['features/base/responsive-ui'];
    const { gumPending } = state['features/base/media'].video;
    return {
        gumPending,
        hasPermissions: permissions.video,
        hasVideoTrack: Boolean(getLocalJitsiVideoTrack(state)),
        isDisabled: isVideoSettingsButtonDisabled(state),
        isOpen: Boolean(getVideoSettingsVisibility(state)),
        visible: !isMobileBrowser() && !isNarrowLayout
    };
}
const mapDispatchToProps = {
    onVideoOptionsClick: toggleVideoSettings
};
export default translate(connect(mapStateToProps, mapDispatchToProps)(VideoSettingsButton));
