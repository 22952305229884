import React from 'react';
import Icon from '../../../icons/components/Icon';
import Popover from '../../../popover/components/Popover.web';
/**
 * Displays the `ToolboxButtonWithIcon` component.
 *
 * @param {Object} props - Component's props.
 * @returns {ReactElement}
 */
export default function ToolboxButtonWithPopup(props) {
    const { ariaControls, ariaExpanded, ariaHasPopup, ariaLabel, children, icon, iconDisabled, iconId, onPopoverClose, onPopoverOpen, popoverContent, styles, trigger, visible } = props;
    if (!icon) {
        return (React.createElement("div", { className: 'settings-button-container', style: styles },
            React.createElement(Popover, { content: popoverContent, headingLabel: ariaLabel, onPopoverClose: onPopoverClose, onPopoverOpen: onPopoverOpen, position: 'top', trigger: trigger, visible: visible }, children)));
    }
    const iconProps = {};
    if (iconDisabled) {
        iconProps.className
            = 'settings-button-small-icon settings-button-small-icon--disabled';
    }
    else {
        iconProps.className = 'settings-button-small-icon';
        iconProps.role = 'button';
        iconProps.tabIndex = 0;
        iconProps.ariaControls = ariaControls;
        iconProps.ariaExpanded = ariaExpanded;
        iconProps.containerId = iconId;
    }
    return (React.createElement("div", { className: 'settings-button-container', style: styles },
        children,
        React.createElement("div", { className: 'settings-button-small-icon-container' },
            React.createElement(Popover, { content: popoverContent, headingLabel: ariaLabel, onPopoverClose: onPopoverClose, onPopoverOpen: onPopoverOpen, position: 'top', visible: visible },
                React.createElement(Icon, { ...iconProps, ariaHasPopup: ariaHasPopup, ariaLabel: ariaLabel, size: 16, src: icon })))));
}
