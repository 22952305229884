import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from '../../../base/i18n/functions';
import { updateSettings } from '../../../base/settings/actions';
import { shouldHideShareAudioHelper } from '../../../base/settings/functions.web';
import { toggleScreensharing } from '../../../base/tracks/actions.web';
import Checkbox from '../../../base/ui/components/web/Checkbox';
import Dialog from '../../../base/ui/components/web/Dialog';
/**
 * Component that displays the audio screen share helper dialog.
 */
class ShareAudioDialog extends Component {
    /**
     * Instantiates a new component.
     *
     * @inheritdoc
     */
    constructor(props) {
        super(props);
        this._onContinue = this._onContinue.bind(this);
        this._onSelectHideShareAudioHelper = this._onSelectHideShareAudioHelper.bind(this);
    }
    /**
     * Continue the normal screen sharing flow when the user clicks continue.
     *
     * @returns {boolean}
     */
    _onContinue() {
        // Pass undefined as the first parameter so the underlying logic decides weather or not to stop screen sharing.
        this.props.dispatch(toggleScreensharing(undefined, true));
        return true;
    }
    /**
     * Callback invoked when the hide audio helper checkbox has been selected. This setting will be persisted in
     * the local storage, thus the dialog won't be displayed again.
     *
     * @param {Object} e - The key event to handle.
     * @returns {void}
     */
    _onSelectHideShareAudioHelper({ target: { checked } }) {
        this.props.dispatch(updateSettings({ hideShareAudioHelper: checked }));
    }
    /**
     * Implements {@Component#render}.
     *
     * @inheritdoc
     */
    render() {
        const { t } = this.props;
        return (React.createElement(Dialog, { ok: { translationKey: 'dialog.shareAudio' }, onSubmit: this._onContinue, size: 'large', titleKey: t('dialog.shareAudioTitle') },
            React.createElement("div", { className: 'share-audio-dialog' },
                React.createElement("img", { className: 'share-audio-animation', src: 'images/share-audio.gif' }),
                React.createElement(Checkbox, { checked: this.props._shouldHideShareAudioHelper, label: t('dialog.hideShareAudioHelper'), name: 'hide-share-audio-helper', 
                    // eslint-disable-next-line react/jsx-no-bind
                    onChange: this._onSelectHideShareAudioHelper }))));
    }
}
/**
 * Maps part of the Redux state to the props of this component.
 *
 * @param {IReduxState} state - The Redux state.
 * @private
 * @returns {IProps}
 */
function _mapStateToProps(state) {
    return {
        _shouldHideShareAudioHelper: Boolean(shouldHideShareAudioHelper(state))
    };
}
export default translate(connect(_mapStateToProps)(ShareAudioDialog));
